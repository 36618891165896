<template>
  <div class="c-app-container">
    <!-- Background overlay -->
    <div class="c-app-overlay"></div>
    <div class="c-app flex-row align-items-start" :style="{backgroundImage: 'url(' + require('@/assets/logo/bg.jpeg') + ')', backgroundSize: 'cover'}">
      <div class="back">
        <div class="form-center-login" style="z-index: 2;"> <!-- Set a higher z-index -->
          <div class="content_">
          <CCardGroup>
            <CCard class="p-4 radius">
              <CCardBody>
                <center>
                  <img src="@/assets/logo/logo.png" width="40%" alt="" />
                  <br >
                </center>
                <CForm class="mt-3">
                  
                  <CInput
                    placeholder="nickname"
                    v-model="form.nickname"
                    autocomplete="nickname"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    v-model="form.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton
                        color="success"
                        @click="submit()"
                        class="px-4 btn-block"
                        >Login</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
                <p class="mt-3 text-center">
                  <router-link to="/pages/survey-alumni">Kembali Ke Daftar Alumni</router-link>
                  <br>
                  <router-link to="/pages/login">Login Admin</router-link>
                </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>


export default {
  name: "Login",

  data() {
    return {
      form: {},
    };
  },
  
  methods: {
    submit() {
      let loader = this.$loading.show();
      setTimeout(() => {
        this.$store
          .dispatch("auth/loginUser", this.form)
          .then((resp) => {
            this.$router.replace({ name: "Basa Eta" });
            this.$toast.success("Login Berhasil");
            loader.hide();
          })
          .catch((e) => {
            loader.hide();
          });
      }, 2000);
    },
  },
};
</script>

<style>
  .radius {
    border-radius: 15px !important;
  }

  .back {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .form-center-login {
    width: 500px;
    height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 0em 2em;
    display: table;
    z-index: 2; /* Set a higher z-index */
    color: #000; /* Set text color to white or any other color that contrasts well with the overlay */
  }

  div.content_ {
    display: table-cell;
    vertical-align: middle;
  }

  .card {
    background-color: #ffffffc7 !important;
  }

  .c-app-container {
    position: relative;
  }

  .c-app-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity here */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

</style>